import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  ArticleTicketInfo,
  GeneratedKnowledgeTicketId
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchArticles } from "../../clients/GeneratedKnowledge/fetchArticles";
import { EmptyState } from "../ArticlesPage/EmptyState";
import { ErrorMessage } from "../ArticlesPage/ErrorMessage";
import { Header } from "../ArticlesPage/Header";
import { Tabs } from "../ArticlesPage/Tabs";
import styles from "./ArticlesPendingPage.module.scss";
import { TicketsTable } from "./TicketsTable";

const ArticlesPendingPage: React.FC = () => {
  const unpublishedArticlesQuery = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED],
    () => fetchArticles({ published: false }),
    { retry: 2 }
  );
  const isLoading = unpublishedArticlesQuery.isLoading;
  const showErrorMessage = unpublishedArticlesQuery.isError;
  const unpublishedArticles = unpublishedArticlesQuery?.data;
  const showEmptyState =
    unpublishedArticles && unpublishedArticles?.length === 0;
  const showTicketsTable =
    unpublishedArticles && unpublishedArticles.length > 0;

  const tickets: ArticleTicketInfo[] = [];
  unpublishedArticles?.forEach((article) => {
    const { ticket_id: ticketID } =
      article.ticketIds[0] || ({} as GeneratedKnowledgeTicketId);
    const ticket = tickets.find((ticket) => {
      const { ticket_id: ticketId } = ticket.id;
      return ticketId === ticketID;
    });
    if (!ticket) {
      tickets.push({
        id: article.ticketIds[0] || ({} as GeneratedKnowledgeTicketId),
        articleIds: [article.id],
        articleCount: 1,
        createdBy: article.authorEmail,
        createdOn: article.createdOn
      });
    } else {
      ticket.articleCount += 1;
      ticket.articleIds.push(article.id);
    }
  });

  return (
    <>
      <Helmet>
        <title>Articles | Pending Articles</title>
      </Helmet>
      <div className={styles.container}>
        <Header />
        <Tabs currentTab="pendingReview" />
        {isLoading && <Spinner />}
        {showErrorMessage && <ErrorMessage />}
        {showEmptyState && <EmptyState />}
        {showTicketsTable && (
          <div className={styles.ticketsTableContainer}>
            <TicketsTable tickets={tickets} />
          </div>
        )}
      </div>
    </>
  );
};

export { ArticlesPendingPage };
