import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import useModal from "@aptedge/lib-ui/src/hooks/useModal/useModal";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  ArticleStatus,
  GeneratedKnowledgeResult,
  GeneratedKnowledgeTicketId,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import truncate from "lodash/truncate";
import { DateTime } from "luxon";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { deleteArticle } from "../../clients/GeneratedKnowledge/deleteArticle";
import { editArticle } from "../../clients/GeneratedKnowledge/editArticle";
import styles from "./ArticlesTableItem.module.scss";
import { EditArticleModal } from "./EditArticleModal";
import { ViewArticleModal } from "./ViewArticleModal";
interface ArticlesTableItemProps {
  article: GeneratedKnowledgeResult;
  idx: number;
}

interface SaveArticleArgs {
  title: string;
  body: string;
}

const triggerEvent = (
  eventName: string,
  ticketId: string | number,
  articleId: string,
  articleTitle: string,
  articleBody?: string
): void => {
  dataLayerPush({
    event: eventName,
    data: {
      article_id: articleId,
      ticket_id: ticketId,
      article_title: articleTitle,
      article_body: articleBody,
      article_status: ArticleStatus.PUBLISHED
    }
  });
};

const ArticlesTableItem: React.FC<ArticlesTableItemProps> = ({
  article,
  idx
}) => {
  const { queryParams, clearAllQueryParams } = useQueryParams();
  const currentArticleId = queryParams.get(QUERY_PARAMS.ARTICLE_ID);

  const { modalVisibility, closeModal, modalRef } = useModal();
  const [modalType, setModalType] = React.useState<"edit" | "view" | null>(
    null
  );
  const isEven = idx % 2 === 0;
  const { ticket_id: ticketId, external_id: externalId } =
    article.ticketIds?.[0] || ({} as GeneratedKnowledgeTicketId);

  const queryClient = useQueryClient();

  const editArticleMutation = useMutation(editArticle, {
    onSuccess: () => {
      closeModal(false);
      queryClient.invalidateQueries(WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE,
        article.id
      ]);
    }
  });

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries(WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE,
        article.id
      ]);
    }
  });

  const handleViewArticleOpen = (): void => {
    triggerEvent(
      GTM_EVENTS.GTM_VIEW_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      article?.title || ""
    );
    clearAllQueryParams();
    setModalType("view");
    closeModal(true);
  };

  const handleEditArticleOpen = (): void => {
    triggerEvent(
      GTM_EVENTS.GTM_EDIT_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      article?.title || ""
    );
    setModalType("edit");
    closeModal(true);
  };

  const handleCloseModal = (): void => {
    clearAllQueryParams();
    setModalType(null);
    closeModal(false);
  };

  const handleSaveChanges = ({ title, body }: SaveArticleArgs): void => {
    triggerEvent(
      GTM_EVENTS.GTM_SAVE_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      title,
      body
    );
    editArticleMutation.mutate({
      articleId: article.id,
      title,
      body,
      published: true
    });
  };

  const handleDeleteArticle = (): void => {
    deleteArticleMutation.mutate({ articleId: article.id });
  };

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
  };

  React.useEffect(() => {
    if (article.id === currentArticleId) {
      setModalType("view");
      closeModal(true);
    }
  }, [article, currentArticleId, setModalType, closeModal]);

  return (
    <>
      <div
        className={classNames(
          styles.container,
          isEven ? styles.containerEven : styles.containerOdd
        )}
      >
        <LightTooltip
          title={article.title}
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                width: "200px"
              }
            }
          }}
        >
          <div>
            <div className={classNames(styles.tableCell, styles.articleItem)}>
              <button
                className={styles.articleButton}
                onClick={handleViewArticleOpen}
              >
                {truncate(article.title, { length: 50 })}
              </button>
            </div>
          </div>
        </LightTooltip>
        <div
          className={classNames(styles.tableCell, styles.ticketItem, {
            [styles.disable]: !Boolean(ticketId || externalId)
          })}
        >
          <Link
            to={`/tickets/${ticketId}`}
            className={classNames({
              [styles.disableLink]: !Boolean(ticketId)
            })}
            onClick={handleLink}
          >
            {Boolean(externalId) ? `Ticket #${externalId}` : "-"}
          </Link>
        </div>
        <div className={classNames(styles.tableCell, styles.dateItem)}>
          {DateTime.fromSeconds(article.createdOn).toFormat("dd MMM yyyy")}
        </div>
        <div className={classNames(styles.tableCell, styles.authorItem)}>
          {article.authorEmail}
        </div>
        <div className={classNames(styles.tableCell, styles.actionsItem)}>
          <LightTooltip title="Edit" placement="top" arrow>
            <button onClick={handleEditArticleOpen}>
              <AeIcons.EditIcon className={styles.editIcon} />
            </button>
          </LightTooltip>
          <LightTooltip title="Delete" placement="top" arrow>
            <button onClick={handleDeleteArticle}>
              <AeIcons.DeleteIcon className={styles.deleteIcon} />
            </button>
          </LightTooltip>
        </div>
      </div>
      {modalVisibility && modalType === "view" && (
        <ViewArticleModal
          ref={modalRef}
          articleId={article.id}
          handleClose={handleCloseModal}
        />
      )}
      {modalVisibility && modalType === "edit" && (
        <EditArticleModal
          ref={modalRef}
          articleId={article.id}
          handleClose={handleCloseModal}
          handleSave={handleSaveChanges}
        />
      )}
    </>
  );
};

export { ArticlesTableItem };
